











import { Component, Inject, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class extends Vue {
  @Prop() analysesCases!: Array<any>;

  private headers = [
    {
      text: "Name",
      align: "start",
      value: "name",
    },
    {
      text: "Label",
      align: "start",
      value: "label",
    },
    {
      text: "Type",
      align: "start",
      value: "type",
    },
  ];

  private selected = [];

  @Watch("selected")
  public datachange() {
    this.$emit("selection-change", this.selected);
  }

  @Watch("analysesCases")
  public analysesCasesChange() {
    this.selected = [];
  }
}
