









































































import { Component, Inject, Vue } from "vue-property-decorator";
import FileSelection from "@/components/common/FileSelection.vue";
import TasksList from "@/components/tasks/TasksList.vue";

import { BackendClient, FileDescriptor } from "ibf-backend";

import AnalysisCaseSelector from "@/components/psa/AnalysisCaseSelector.vue";

@Component({
  components: {
    FileSelection,
    TasksList,
    AnalysisCaseSelector,
  },
})
export default class PSAQuantificationLauncher extends Vue {
  @Inject("backendClient") client!: BackendClient;

  private taskCreating: boolean = false;
  private valid: boolean = true;

  private requiredRule(value: string): any {
    return !!value || "Required.";
  }

  private modelFileDescriptor: FileDescriptor | null = null;

  private analysesCases: Array<any> = [];

  private selectedAnalysesCaseName: Array<string> = [];

  private async launch() {
    this.taskCreating = true;

    const parameters = {
      modelFile: this.modelFileDescriptor!.name,
      analysisCases: this.selectedAnalysesCaseName,
    };

    await this.client.taskApi.createTask({
      type: "PSAQuantification",
      parameters: parameters,
      files: [
        {
          name: this.modelFileDescriptor!.name,
          context: "USER",
          path: this.modelFileDescriptor!.uri,
        },
      ],
    });

    this.taskCreating = false;
  }

  private async psaModelSelected() {
    if (this.modelFileDescriptor != null) {
      // load analysis cases
      this.analysesCases = await (
        await this.client.get(
          "/psa/analysisCase" + this.modelFileDescriptor.uri
        )
      ).data;
    } else {
      this.analysesCases = [];
    }
  }

  private analysesSelectionChanged(selectedAnalysesCases) {
    // store analyses cases to send to task
    this.selectedAnalysesCaseName = selectedAnalysesCases.map((o) => o.name);
    console.log(this.selectedAnalysesCaseName);
  }
}
