import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{staticClass:"text-center"},[_c(VCol,{staticClass:"mb-4"},[_c('h1',{staticClass:"display-1 font-weight-bold mb-3"},[_vm._v("PSA Quantification")])])],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VContainer,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c('file-selection',{attrs:{"label":"Model (psa)","acceptedFiles":"psa","rules":[_vm.requiredRule]},on:{"input":_vm.psaModelSelected},model:{value:(_vm.modelFileDescriptor),callback:function ($$v) {_vm.modelFileDescriptor=$$v},expression:"modelFileDescriptor"}})],1)],1),_c(VRow,[_c(VCol,[_c('analysis-case-selector',{attrs:{"analysesCases":_vm.analysesCases},on:{"selection-change":_vm.analysesSelectionChanged}})],1)],1)],1),_c(VCardActions,[_c(VBtn,{staticClass:"mx-auto",attrs:{"color":"primary","disabled":_vm.taskCreating ||
                _vm.modelFileDescriptor == null ||
                _vm.selectedAnalysesCaseName.length == 0},on:{"click":_vm.launch}},[_c(VProgressCircular,{directives:[{name:"show",rawName:"v-show",value:(_vm.taskCreating),expression:"taskCreating"}],staticClass:"ml-4",attrs:{"indeterminate":""}}),_vm._v(" Start ")],1)],1)],1)],1)],1),_c(VRow,{staticClass:"text-center"},[_c(VCol,{staticClass:"mb-4"},[_c(VCard,{attrs:{"outlined":"","min-height":200}},[_c(VCardTitle,[_vm._v("Last quantifications")]),_c(VCardText,[_c('tasks-list',{attrs:{"tasktype":"PSAQuantification"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }