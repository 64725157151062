
































import { Component, Vue, Prop, Inject } from "vue-property-decorator";
import TaskStatusChip from "./TaskStatusChip.vue";
import { Task, BackendClient } from "ibf-backend";
import moment from "moment-timezone";

@Component({
  components: {
    TaskStatusChip,
  },
})
export default class TasksList extends Vue {
  @Prop() tasktype!: string;
  @Prop({ default: "task" }) taskurl!: string;
  private tasks = Array<Task>();
  private headers = [
    {
      value: "id",
      text: "Id",
    },
    {
      value: "status",
      text: "Status",
    },
    {
      value: "type",
      text: "Type",
    },
    {
      value: "creationDate",
      text: "Creation date",
    },

    { text: "Actions", value: "actions", sortable: false },
  ];

  private timer: any = null;

  private getTz() {
    return moment.tz.guess();
  }

  @Inject("backendClient") client!: BackendClient;

  mounted() {
    this.populateTasks();
    this.timer = setInterval(() => {
      this.populateTasks();
    }, 2000);
  }

  beforeDestroy() {
    clearInterval(this.timer);
  }

  async populateTasks() {
    let allTasks: Array<Task> = await this.client.taskApi.getTasks();
    this.tasks = allTasks.filter((t) => t.type === this.tasktype);
  }

  private showTask(item: any) {
    this.$router.push({ path: `/${this.taskurl}/${item.id}` });
  }

  async deleteTask(item: any) {
    await this.client.taskApi.deleteTask(item.id);
    this.populateTasks();
  }
}
