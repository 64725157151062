




































































import { BackendClient, FileDescriptor } from "ibf-backend";
import Vue from "vue";
import { Component, Inject, Prop, Watch } from "vue-property-decorator";

@Component
export default class FileSelection extends Vue {
  @Inject("backendClient") client!: BackendClient;

  @Prop() acceptedFiles!: string;
  @Prop() label!: string;
  @Prop({ default: () => [] }) rules!: Array<any>;
  @Prop() value!: FileDescriptor | FileDescriptor[]; // this is the v-model prop
  @Prop({ default: false }) multiple!: boolean;

  private selectedFiles: FileDescriptor | FileDescriptor[] = []; // v-select value : not using directly prop because you can't mutate a prop

  /* @Watch("value")
  onPropertyChanged(value: FileDescriptor[], oldValue: FileDescriptor[]) {
    if (value != this.selectedFiles) {
      this.selectedFiles = value;
    }
  }*/

  private updateValue() {
    this.$emit("input", this.selectedFiles);
  }

  private populateFilesLoading = false;

  private files: Array<FileDescriptor> = [];

  private currentFileUri = "/";

  mounted() {
    this.populateFiles();
  }

  async populateFiles() {
    this.populateFilesLoading = true;

    let foundedFiles: Array<FileDescriptor> = await this.client.fileApi.getFiles(
      this.currentFileUri
    );

    let acceptedFilesArray = this.acceptedFiles.split(",");

    let filteredFiles: Array<FileDescriptor> = [];

    // we always need selected files in list
    if (Array.isArray(this.selectedFiles)) {
      filteredFiles.push(...this.selectedFiles);
    } else {
      filteredFiles.push(this.selectedFiles);
    }

    for (let f of foundedFiles) {
      if (
        f.directory ||
        acceptedFilesArray == null ||
        acceptedFilesArray.length == 0
      ) {
        filteredFiles.push(f);
      }

      for (let extension of acceptedFilesArray) {
        if (f?.name?.toLowerCase().endsWith(extension.toLowerCase())) {
          filteredFiles.push(f);
        }
      }
    }

    this.files = filteredFiles;

    this.populateFilesLoading = false;
  }

  navigateTo(item) {
    this.currentFileUri = item.uri;
    this.populateFiles();
  }

  navigateBack() {
    if (this.currentFileUri === "/") {
      return;
    }

    var split = this.currentFileUri.split("/");
    this.currentFileUri = split.slice(0, split.length - 2).join("/") + "/";
    this.populateFiles();
  }
}
