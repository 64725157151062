import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSelect,{attrs:{"multiple":_vm.multiple,"return-object":"","items":_vm.files,"item-text":"name","loading":_vm.populateFilesLoading,"clearable":true,"prepend-icon":"mdi-file","label":_vm.label,"rules":_vm.rules,"menu-props":{ offsetY: true },"chips":""},on:{"change":function($event){return _vm.updateValue()}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c(VListItem,{attrs:{"dense":""}},[_c(VListItemIcon,[(_vm.currentFileUri != '/')?_c(VIcon,{attrs:{"color":"grey lighten-1"},on:{"click":function($event){return _vm.navigateBack()}}},[_vm._v("mdi-chevron-left")]):_vm._e()],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.currentFileUri)}})],1)],1),_c(VDivider)]},proxy:true},{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemIcon),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("No file")])],1)],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [(item.directory)?[_c(VListItem,{on:{"click":function($event){return _vm.navigateTo(item)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-folder")])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.name)}})],1),_c(VListItemAction,[_c(VIcon,{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-chevron-right")])],1)],1)]:[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-file")])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.name)}})],1)]]}}]),model:{value:(_vm.selectedFiles),callback:function ($$v) {_vm.selectedFiles=$$v},expression:"selectedFiles"}})}
var staticRenderFns = []

export { render, staticRenderFns }